import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { EndStop } from '$/types'

export default function useEndStopState(): EndStop {
  const { state } = useContext(StateContext)

  return state.endStop
}
