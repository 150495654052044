import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { StartStop } from '$/types'

export default function useStartStopState(): StartStop {
  const { state } = useContext(StateContext)

  return state.startStop
}
