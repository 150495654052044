import { amplitudeKey, latestGitCommitHash } from '$/config'
import type { Optional } from '$/types'

import type { AmplitudeClient, LogReturn } from 'amplitude-js'

let amplitude: AmplitudeClient

export const getAmplitude = async (): Promise<AmplitudeClient> => {
  if (!amplitude) {
    const amplitudeLibrary = await import('amplitude-js')

    amplitude = amplitudeLibrary.getInstance()

    amplitude.init(amplitudeKey)
    amplitude.setVersionName(latestGitCommitHash!)
  }

  return amplitude
}

export const setUserId = async (userId: Optional<string>): Promise<void> =>
  (await getAmplitude()).setUserId(userId)

export const track = async (
  eventName: string,
  data?: Record<string, unknown>,
): Promise<LogReturn> => (await getAmplitude()).logEvent(eventName, data)
