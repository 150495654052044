import { featuresForUsersWhoAreNotSubscribed, plans } from '$/config'
import type {
  MaximumNumberOfVehicles,
  Subscription,
  PlanData,
  Stops,
  User,
} from '$/types'

const getMaxDriversAndStops = (
  user: User,
  productIds: Map<string, unknown>,
): Record<string, number> => {
  const { anonymous, unsubscribed } = featuresForUsersWhoAreNotSubscribed
  const { singleDriver, smallTeam, largeTeam } = plans

  // Logged out defaults
  let { maxDrivers } = anonymous
  let { maxStops } = anonymous

  // Extend stop limit for logged in users
  if (!user?.isAnonymous) {
    maxStops = Math.max(maxStops, unsubscribed.maxStops)
    maxDrivers = Math.max(maxDrivers, unsubscribed.maxDrivers)
  }

  // eslint-disable-next-line prettier/prettier
  [...productIds.keys()].forEach((productId) => {
    switch (productId) {
      case singleDriver.id:
        maxDrivers = Math.max(maxDrivers, singleDriver.features.maxDrivers)
        maxStops = Math.max(maxStops, singleDriver.features.maxStops)
        break

      case smallTeam.id:
        maxDrivers = Math.max(maxDrivers, smallTeam.features.maxDrivers)
        maxStops = Math.max(maxStops, smallTeam.features.maxStops)
        break

      case largeTeam.id:
        maxDrivers = Math.max(maxDrivers, largeTeam.features.maxDrivers)
        maxStops = Math.max(maxStops, largeTeam.features.maxStops)
        break

      default:
        break
    }
  })

  return { maxDrivers, maxStops }
}

const isSubscribed = (subscription: Subscription): boolean =>
  subscription.stripeProductIds.size > 0

const isSubscribedToPlan = (
  plan: PlanData,
  subscription: Subscription,
): boolean => subscription.stripeProductIds.has(plan.stripeProductId)

const hasValidSubscription = (subscription: Subscription): boolean => {
  return subscription.validUntil > Date.now() / 1000
}

const isMaxStopsLimitReached = (
  stops: Stops,
  subscription: Subscription,
): boolean => {
  return stops.size >= subscription.maxStops
}

const isMaxDriversLimitReached = (
  maximumNumberOfVehicles: MaximumNumberOfVehicles,
  subscription: Subscription,
): boolean => {
  return maximumNumberOfVehicles > subscription.maxDrivers
}

const getSubscriptionPlanName = (subscription: Subscription): string => {
  // This conditional chain will always fallback to the
  // top-tier subscription in case user has more than one.
  if (subscription.stripeProductIds.has(plans.largeTeam.id)) {
    return 'Large team'
  }

  if (subscription.stripeProductIds.has(plans.smallTeam.id)) {
    return 'Small team'
  }

  if (subscription.stripeProductIds.has(plans.singleDriver.id)) {
    return 'Single driver'
  }

  return ''
}

export {
  getMaxDriversAndStops,
  getSubscriptionPlanName,
  hasValidSubscription,
  isMaxDriversLimitReached,
  isMaxStopsLimitReached,
  isSubscribed,
  isSubscribedToPlan,
}
