import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { Subscription } from '$/types'

export default function useSubscriptionState(): Subscription {
  const { state } = useContext(StateContext)

  return state.subscription
}
