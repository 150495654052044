import React from 'react'

import type { Action, AppState } from '$/types'
import { initialState } from '$/config'

interface StateContextInit {
  state: AppState
  dispatch: React.Dispatch<Action>
}

export const StateContext = React.createContext<StateContextInit>({
  state: initialState,
  dispatch: () => {
    /* Nothing here */
  },
})
