import { useRef } from 'react'

const useFocus = (): [
  htmlElRef: React.MutableRefObject<any>,
  setFocus: () => void,
] => {
  const htmlElRef: React.MutableRefObject<any> = useRef(null)
  const setFocus = () => {
    htmlElRef.current.focus()
    htmlElRef.current.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    })
  }

  return [htmlElRef, setFocus]
}

export default useFocus
