import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { User } from '$/types'

export default function useUserState(): User {
  const { state } = useContext(StateContext)

  return state.user
}
