import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { GeoCoords } from '$/types'

export default function useGeolocationState(): GeoCoords | undefined {
  const { state } = useContext(StateContext)

  return state.geolocation
}
