import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { Stops } from '$/types'

export default function useStopsState(): Stops {
  const { state } = useContext(StateContext)

  return state.stops
}
