import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { RouteState } from '$/types'

export default function useRouteState(): RouteState {
  const { state } = useContext(StateContext)

  return state.route
}
