import { useContext } from 'react'

import { StateContext } from '$/contexts'
import type { OpenDialogType } from '$/types'

export default function useDialogState(): OpenDialogType {
  const { state } = useContext(StateContext)

  return state.openDialog
}
